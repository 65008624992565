//Variables
@import 'abstracts/variables';

//Vendor
@import '~csstyle/csstyle';

//Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

//Elements
@import 'elements/superscript';
@import 'elements/subscript';
